.deposit-status {
    .iframe-container {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%; // 16:9 aspect ratio
  
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  
    .bx {
      font-size: 1.5em;
      vertical-align: middle;
    }
  
    .card {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease-in-out;
  
      &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }
    }
  
    .badge {
      font-weight: 500;
    }
  }